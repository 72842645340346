/*
 * Tous ce qui concerne la gestion des wishlist (fiche produit et page wishlist)
 * product_right.php, wishlist_index.php
 */

/**
 * Dans la fiche produit. Le bouton ajouter à la wishlist. (product_right.php)
 */
function addToWishlist() {
    var btn = $(this);
    var idElt = btn.data('idelt');
    var productId = (btn.data('productid') ? btn.data('productid') : $("#produit_id").val());
    var colorId = $('#couleurProd_' + idElt).length == 1 ? $('#couleurProd_' + idElt).val() : $('#couleurProd').val();
    var sizeId = $('#tailleProd_' + idElt).length == 1 ? $('#tailleProd_' + idElt).val() : $('#tailleProd').val();
    var datas = {
        product_id: productId,
        color_id: colorId,
        size_id: sizeId,
        need_color_id: 'true',
        need_size_id: 'true'
    };
    $.ajax({
        url: path_relative_root + "ajax_add_to_wishlist.php",
        type : 'post',
        data: datas
    })
        .done(function(resp) {
            var $alertboxLogin = $("#abox");
            var $alertboxBtns = $("#abox .wrap_btn_abox");
            $alertboxLogin.addClass('box_wishlist');

            if (resp.error) {

                var message = resp.result.error_message ? resp.result.error_message : resp.message;
                if (resp.message === "login_required") {
                    var wishlistSubmit = $('.form_submit.form_submit_wishlist', $alertboxLogin);
                    if (wishlistSubmit.length === 0) {
                        $alertboxBtns
                            .append($(
                                '<div class="form_submit form_submit_wishlist">' +
                                '<button class="button dark"  href="javascript:void(0)" onclick="wishlistRedirectLogin(\'' + productId + '\');">' +
                                '<span>' +
                                resp.result.btn_connexion +
                                '</span>' +
                                '</button>' +
                                '</div>')
                            );
                    } else {
                        wishlistSubmit.css('display', 'flex');
                    }
                    // On cache le bouton original
                    $('#abox .form_submit.original').css('display', 'none');
                }
                if (resp.result.error_message) {
                    alertV2(Translator.translate(resp.result.error_message));
                } else {
                    alertV2(Translator.translate('error_wishlist_' + message.toLowerCase()));
                }
            } else {

                //evenement DataLayer
                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        'event': 'addToWishlist',
                        'products': {
                            'product_id': productId,
                        }
                    });
                }
                var dl_v3_obj = resp.result.dlv3_wishlist_item;
                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && Object.keys(dl_v3_obj).length > 0) {
                    DataLayerTrigger.addToWishList(dl_v3_obj['items'], dl_v3_obj['actionField'])
                }


                btn.addClass("existToWishlistButton");
                btn.attr("onclick", "");
                if (typeof wishlist !== 'undefined') {
                    wishlist.push(resp.result.wishlist_row_array);
                }
                addToWishlistUpdateHeader(resp.result.wishlist_size);
            }
        });
}

/**
 * Permet d'ajour un produit à la wishlist via le rayon (ou la recherche)
 * Utilisation : app/_components/product/achat_express.php/achat_express_search.php
 */
function addToWishlistRay() {

    // On utilise le même que dans la fiche produit car cela fait la même chose
    // mais on donne un nom différent au cas où
    addToWishlist.call(this);
}

/**
 *
 * @param productId : l'id du produit
 * @param itm_rr_id : l'id du regroupement
 * @returns {boolean}
 */
function checkProductInWishlist(productId, itm_rr_id) {

    var elem = itm_rr_id ? productId + itm_rr_id : productId;
    var colorId = 0;
    var couleurProdElt = $("#ligne_couleur_" + elem + " input[name='itm_color']:checked");
    if (couleurProdElt.length) {
        colorId = couleurProdElt.val();
    } else if ($("#couleurProd")) {
        colorId = $("#couleurProd").val();
    }
    var sizeId = 0;
    var tailleProdElt = $("#size_list_" + elem + " input[name='itm_size']:checked");
    if (tailleProdElt.length) {
        sizeId = tailleProdElt.val();
    } else if ($("#tailleProd")) {
        sizeId = $("#tailleProd").val();
    }

    if (typeof elem != "undefined" && typeof colorId != "undefined" && typeof sizeId != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data:{'product_id': elem, 'size_id': sizeId, 'color_id': colorId},
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true, productId, itm_rr_id);
                    return true;
                } else {
                    changeBtnAddWishlist(false, productId, itm_rr_id);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false, productId, itm_rr_id);
        return false;
    }
}

/*
 * Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
 * Aurelien Renault
 * @param in_wishlist : true ou false selon si dans wishlist
 * @param product_id : l'id du produit
 * @param itm_rr_id : l'id du regroupement
 */
function changeBtnAddWishlist(in_wishlist, product_id, itm_rr_id) {

    var elem = itm_rr_id ? product_id + itm_rr_id : product_id;
    var btn = $("#addToWishlistButton");
    if (!btn.length) {
        btn = $("#addToWishlistButton_" + elem);
    } else if (!btn.length) {
        btn = $("#addToWishlistButton_" + elem + "_2");
    }
    if (in_wishlist) {
        btn.attr("onclick", "");
        btn.attr("href", path_relative_root + create_link('wishlist'));
        btn.addClass("existToWishlistButton");
    } else {
        btn.attr("onclick", 'addToWishlist.call(this);return false;');
        btn.attr("href", "#");
        btn.removeClass("existToWishlistButton");
    }
}

function wishlistRedirectLogin(div_id) {
    $.get(path_relative_root + "ajax_add_to_wishlist.php?login_required=1", function () {
        document.location.href = path_relative_root + create_link('connexion_login') + "?from=front&div_id=" + div_id;
    });
}

function addToWishlistUpdateHeader(nr) {
    if (nr === 0) {
        $(".wrapper_menu_compte_container .item.wishlist").hide();
        $("#number_wishlist_top").html('(0)');
    } else {
        $('.wrapper_menu_compte_container .wishlist_off').removeClass('wishlist_off');
        $(".wrapper_menu_compte_container .item.wishlist").show();
        $(".block_top_item.my_account .wishlist a").html(Translator.translate('ma_wishlist')+ ' (' + nr + ')');
    }
}

function updateCartElements () {
    var nr = $(".cart_main_table.wishlist.dropper .cart_product_line.draggableElem").length;
    addToWishlistUpdateHeader(nr);
    scrollToDonationBox();
    cartSwipeGondole();
    // Check if the unit value upselling_price is 0
    if ($('#threshold_fdp_txt .unit').text() == '0') {
        $('.upselling.price').css('display', 'none');
    }
}

function scanForChanges() {
    var totalPrice = 0;
    selectedProducts = []; // global
    $(".checkbox_select_product").each(function () {
        var element = $(this);
        var id = element.attr("data-id");

        if (element.is(':checked')) {
            var price = parseFloat(element.attr('data-price'));

            if (price) {
                totalPrice += price;
            }

            selectedProducts.push(id);
            if (element.attr("pointscadeau")) {
                totalPoints += parseInt(element.attr("pointscadeau"));
            }
        }
    });
    var txt = "",
        text = "";
    var priceSpan = $("#total_price");
    switch (selectedProducts.length) {
        case 0:
            txt = $texts[0] + ' :';
            priceSpan.html('0<span class="devise">&euro;</span><sup></sup><span class="decimal_price"></span>');
            break;
        case 1:
            text = $texts[1];
        default:
            if (text === '')
                text = $texts[2];

            var pricefloor = Math.floor(totalPrice);

            var decimal = '';
            if (pricefloor !== totalPrice) {
                decimal = Math.round((totalPrice - pricefloor) * 100);
                decimal = '' + decimal;
                if (decimal.length === 1) {
                    decimal = decimal + '0';
                }
            }

            priceSpan.html(pricefloor + '<span class="devise">&euro;</span><sup></sup><span class="decimal_price">' + decimal + '</span>');
            txt = '<span class="wishlist_count">' + selectedProducts.length + '</span> ' + text + ' :';
    }

    $(".txt", "#nrArticlesSelected").html(txt);

}

function addProductToCart(product_id, color_id, size_id) {
    $.post(path_relative_root + create_link('ajax_wishlist_add_product_to_cart'),
        {
            product_id: product_id,
            color_id: color_id,
            size_id: size_id
        }, function (resp) {
            if (resp === "ok") {
                document.location.href = path_relative_root + create_link('order_basket');
                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && product_id !== null && product_id !== undefined) {
                    DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, product_id, "add_to_cart");
                } else {
                    var data_layer_products = window.data_layer_products;
                    var dtl_products = [];

                    if (Object.keys(data_layer_products).length > 0) {
                        for (const property in data_layer_products) {
                            if (property !== product_id) {
                                dtl_products.push(data_layer_products[property])
                            }
                        }
                    }
                    if (window.dataLayer !== undefined && dtl_products.length > 0) {
                        window.dataLayer.push({
                            'event': 'addToCart',
                            'ecommerce': {
                                'currencyCode': 'EUR',
                                'add': {
                                    'products': dtl_products
                                }
                            }
                        });
                    }
                }
            } else {
                alertV2(Translator.translate('error_add_basket'));
            }
        });
}

function addCustomizedProductToCart(pc_id) {
    var errors = [];

    //get data
    var customizationData = {};
    $.ajax({
        url: path_relative_root + create_link('ajax_configurator_get_configuration'),
        type: 'post',
        async: false,
        data: { pc_id: pc_id },
        dataType: 'json',
        success: function(response) {
            if (response.success) {
                customizationData = response.json;
            }
        }
    });

    // add main product to basket
    if (customizationData.steps.length) {
        //send main product
        customizationData.product.configurator_product = false;
        customizationData.product.reinit_basket_virtuel_parent_id = true;
        customizationData.product.set_session_basket_as_parent = true;
        var response = sendProductToBasket(customizationData.product);
       // if main product added, add other products
        if (response.error !== undefined && response.error !== 0) {
            if (response.error.msg !== '' && response.error.msg !== undefined) {
                alertV2(Translator.translate('error_insert_basket') + '<br>' + (errors.join('<br>')));
            }
        } else {
            $.each(customizationData.steps, function (index, customizationData) {
                $.each(customizationData.customization, function (indexProduct, product) {
                    product.id = product.product_id;
                    product.configurator_product = true;
                    var response = sendProductToBasket(product);
                    // On regarde si il y a eu une erreur dans l'ajout (cas plus de stock)
                    if (response.error !== undefined) {
                        if (response.error.msg !== '' && response.error.msg !== undefined) {
                            errors.push(response.error.msg);
                        }
                    }
                });
            });

            if(errors.length) {
                alertV2(Translator.translate('error_insert_basket') + '<br>' + (errors.join('<br>')));
            }
            else {
                //update produit_configurator_data
                $.ajax({
                    url: path_relative_root + create_link('ajax_configurator_update_virtual_basket_id'),
                    type: 'post',
                    async: false,
                    data: {
                        pc_id: pc_id
                    },
                    dataType: 'json',
                    success: function(response) {
                        if (response.success) {
                            document.location.href = path_relative_root + create_link('order_basket');
                        }
                        else {
                            alertV2(Translator.translate('error_add_basket'));
                        }
                    }
                });
            }
        }
    }
}

function sendProductToBasket(product) {
    var response = {};
    $.ajax({
        url: path_relative_root + "ajax_upd_panier.php",
        type: "post",
        dataType: 'json',
        async: false,
        data: {
            configurator: true,
            idprod: product.id,
            idcouleur: product.color_id,
            idtaille: product.size_id,
            panierid: 0,
            basket_virtuel_parent_id: product.basket_virtuel_parent_id,
            qte: 1,
            configurator_product: product.configurator_product,
            dataTypeReturn: 'json',
            reinit_basket_virtuel_parent_id: product.reinit_basket_virtuel_parent_id != undefined ? product.reinit_basket_virtuel_parent_id : false,
            set_session_basket_as_parent: product.set_session_basket_as_parent != undefined ? product.set_session_basket_as_parent : false,
        },
        success: function (res) {
            response = res;
        }
    });
    return response;
}

/**
 * Envoie le formulaire de la lightbox wishlist avec gestion des erreurs
 * Utilisation : wishlist_index.php
 */
function sendWishListForm(form) {

    var friendMail = form.elements['friend_email'].value;
    var inputMailElm = $(form.elements['friend_email']);

    if (friendMail.length > 0) {

        inputMailElm.removeClass('inputErr');
        var data = {
            'to_mails': friendMail,
            'to_message': 'Wishlist',
            'your_name': form.elements['customer_firstname'].value,
            'your_mail': form.elements['customer_email'].value
        };

        var error = false;
        var reportMessage = '';
        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist_form'),
            data: data
        }).done(function (response) {

            $('.zone_text').css({border: ''});

            if (response.split(',')[0] === 'ok') {
                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
                    dataLayer.push({
                        "event": "sendWish_click",
                    });
                }
                var sing = (response.split(',').length > 2) ? 'sendfriend_ok_plur' : 'sendfriend_ok_sing';
                reportMessage = Translator.translate(sing);
            }
            if (response === '1' || response === '2') {
                error = true;
                reportMessage = Translator.translate('sendfriend_error_mail_friends_invalid');
            } else if (response === '3') {
                error = true;
                reportMessage = Translator.translate('sendfriend_error_no_message');
            } else if (response === '4') {
                error = true;
                reportMessage = Translator.translate('sendfriend_error_no_name');
            } else if (response === '5' || response === '6') {
                error = true;
                if (response === '5') {
                    reportMessage = Translator.translate('sendfriend_error_no_my_mail');
                } else {
                    reportMessage = Translator.translate('sendfriend_error_my_mail_invalid');
                }
            } else if (response === 'token_error') {
                error = true;
                reportMessage = Translator.translate('error_token_security');
            } else if (response.split(',')[0] === '7') {
                error = true;
                reportMessage = Translator.translate(sing);
            }

            var emailReportElm = $('.email_wishlist_report');
            emailReportElm.html(reportMessage);
            if (error) {
                inputMailElm.parent('label').addClass('w-has-error');
                inputMailElm.addClass('inputErr');
                emailReportElm.addClass('error');
            } else {
                emailReportElm.removeClass('error');
                inputMailElm.val('');
            }
            emailReportElm.removeClass('cache');
        });

    } else {
        inputMailElm.parent('label').addClass('w-has-error');
        inputMailElm.addClass('inputErr');
    }

    return false;
}

/**
 * Utiliser pour ajouter un magasin dans ses favoris (boutique_details)
 */
function addToMyFavorites() {

    var is_favorite_elem = document.getElementById('is_favorite');

    var self = $(this);
    var magasin_id = self.attr('data-magasinid');
    var client_id = self.attr('data-clientid');
    var action = self.attr('data-action');

    // Si l'action est de détruire alors on est dans favorites_stores et on souhaite supprimer le bloc en entier
    if (action == 'destroy') {
        var act = 'del';
    } else {
        // On est dans store_details et on doit recupérer le statut actuel du magasin pour savoir si on doit le supprimer ou l'ajouter
        if (is_favorite_elem.value == '1') {
            var act = 'del';
        } else {
            var act = 'add';
        }
    }

    $.ajax({
        url: path_relative_root + 'ajax_manage_favorites_stores.php',
        type: 'post',
        dataType: 'html',
        data: 'act=' + act + '&magasin_id=' + magasin_id,
        success: function (response) {

            if (response) {

                // Destruction du bloc
                if (action == 'destroy') {
                    self.slideUp('slow', function () {
                        self.remove();
                    });
                } else {
                    if (act == 'add') {
                        var libelle = '<span>' + Translator.translate('delete_to_favorites') + '</span>';

                        is_favorite_elem.value = 1;
                    } else {
                        var libelle = '<span>' + Translator.translate('add_to_favorites') + '</span>';

                        is_favorite_elem.value = 0;
                    }
                    self.html(libelle);
                }
            }
        }
    });
}