// TUNNEL MOVE DISCLAIMER
$(function () {

    // Moving elements other divs in Tunnel
    var source;
    var destination;
    
    // Move Beware message to correct place
    if ($('body.cart').length > 0 && $('.product_line_basket.isConfig').length > 0) {
        if ($('.cart.body_login').length < 1 && $('.cart.step_2').length < 1) {
            source = $('.beware_msg_wrapper');
            destination = $('.left_side.elem_tunnel');

            moveElemTo(source, destination, 'append');
        }
    } else {
        if ($('.beware_msg_wrapper').length) {
            $('.beware_msg_wrapper').hide();
        }
    }

    // Move address form error from step_2_0 to correct place
    if ($('body.step_2_0 #mes_err').length) {
        source = $('#mes_err');
        destination = $('.left_side.elem_tunnel.addressWrapper');

        moveElemTo(source, destination, 'prepend');
    }

    if ($('.cart.step_2 .bloc_livraison .title_horraire').length) {
        $('.cart.step_2 .bloc_livraison .title_horraire').on('click', function() {
            if (!$(this).hasClass('deployed')) {
                if ($(this).parent().find('.horaire').length > 0) {
                    $(this).addClass('deployed');
                    $(this).parent().find('.horaire').slideDown(500);
                }
            } else {
                if ($(this).parent().find('.horaire').length > 0) {
                    $(this).removeClass('deployed');
                    $(this).parent().find('.horaire').slideUp(500);
                }
            }
        });
    }

    if ($('.right_side .bloc_question').length) {
        var blocQuestion = $('.right_side .bloc_question').detach(); // Detach from .right_side.elem_tunnel
        $('.left_side.elem_tunnel').append(blocQuestion); // Append to .left_side.elem_tunnel
    }

    $(document).ready(function() {
        if ($('body').is('.body_login.cart.in_tunnel') && $('#subtitleLogin').length) {
            $('#subtitleLogin, .subtitle.legal_notices').appendTo('.login_wrapper');
            $('.subtitle.legal_notices').show();
        }

        if ($('body.step_2_0 .deconnection_tunnel').length && $('#cart_total').length) {
            $('.deconnection_tunnel').prependTo('#cart_total');
        }

        if ($('.wrapper_moncompte .wrapper_form .w-info-formV2').length) {
            $('.w-info-formV2 .w-input-container.mail-2').append(
                '<span class="mandatoryTxtForm">' + Translator.translate('mandatory_txt_form') + '</span>'
            );
        }
    });

    $('body').on('click', '.toggle_modal_actions', function() {
        var $parent = $(this).closest('.product_line_basket');
        
        $(this).fadeOut('fast');
        $parent.addClass('blocModifyVisible')
               .find('.cart_item_actions').hide().css('display', 'flex').slideDown('slow');
        $parent.find('.cart_item_actions_close').fadeIn('slow');
    });

    $('body').on('click', '.cart_item_actions_close', function() {
        var $parent = $(this).closest('.product_line_basket');
        
        $(this).fadeOut('fast');
        $parent.find('.cart_item_actions').slideUp('fast');
        $parent.find('.toggle_modal_actions').fadeIn('slow');
        $parent.removeClass('blocModifyVisible');
    });

    $("h1.recap_cart_title").click(function(){
        if (!$("body").hasClass("step_1") && ($("body").hasClass("cart") || $("body").hasClass("in_tunnel"))) {
            $(this).toggleClass("open");
            $(".total_produit_dropdown").slideToggle();
        }
    });
    
    if ($('.bankThanksTop').length) {
        var elementsToMove = $('.bankThanksTop').children().not('#around_me_map');
        
        $('.thanksRight').prepend(elementsToMove);
    }

    if ($('.thanksRightWrapper .num_order').length) {
        var orderMoveToTitle = $('.thanksRightWrapper .num_order');
        
        $('.thanksRight h1 span:first-of-type').prepend(orderMoveToTitle);
    }

    $('body').on('click', '.payment_mode_btn span', function() {
        if ($('#bloc_erreur_order').length) {
            $('#bloc_erreur_order').hide();
        }
    });

    cartSwipeGondole();
    cartExistInWishlist();
});

function moveElemTo(source, destination, method) {
    // We use show() because those elements are initially hidden to prevent bad visuals from the action
    if(destination.length) {
        if(method == 'prepend') {
            source.prependTo(destination);
        } else {
            source.appendTo(destination);
        }
        source.show();
    }
}

function cartSwipeGondole() {
    if ($('body.step_1').length && (!$('.gondole_cart_wrapper').length)) {
        setTimeout(function () {
            let title_gondole_cart = $('.title_gondole');
            let basket_gondole_cart = $('.basket_gondole');

            if (basket_gondole_cart.length) {
                basket_gondole_cart.fadeIn();
                $('.basket_gondole .swiper-slide').wrapAll('<div class="gondole_cart_wrapper"><div class="swiper swiper-container basket_gondole_swiper"><div class="swiper-wrapper"></div></div></div>');

                let basket_gondole_swiper = $('.basket_gondole_swiper');

                $('<div class="swiper-button-next"></div><div class="swiper-button-prev"></div>').appendTo('.gondole_cart_wrapper');

                if ($('.basket_gondole_swiper .swiper-slide').length > 2) {
                    var basketGondoleSwiper = new Swiper('.basket_gondole_swiper', {
                        slidesPerView: 3,
                        spaceBetween: 15,
                        loop: true,
                        roundLengths: true,
                        observer: true,
                        observeParents: true,
                        navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        },
                        breakpoints: {
                            640: {
                                slidesPerView: 1.5,
                                spaceBetween: 10
                            },
                            1023: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            1450: {
                                slidesPerView: 3,
                                spaceBetween: 15,
                            },
                        },
                    });
                }
            }
        }, 1000);
    }
}

function cartExistInWishlist() {
    if ($('.cart_main_table.wishlist').length && $('.cart_main_table.basket').length) {
        var wishlistProducts = [];

        $('.cart_main_table.wishlist .cart_product_line').each(function() {
            var product = $(this);
            var productId = product.find('a.wishToBasket').attr('data-produit-id');
            var sizeId = product.find('a.wishToBasket').attr('data-size-id');
            var colorId = product.find('a.wishToBasket').attr('data-color-id');

            wishlistProducts.push({ productId, sizeId, colorId, element: product });
        });

        $('.cart_main_table.basket .cart_product_line').each(function() {
            var basketProduct = $(this);
            var basketProductId = basketProduct.find('a.product_towish').attr('data-productid');
            var basketSizeId = basketProduct.find('a.product_towish').attr('data-sizeid');
            var basketColorId = basketProduct.find('a.product_towish').attr('data-colorid');

            wishlistProducts.forEach(function(wishlistProduct) {
                if (wishlistProduct.productId === basketProductId &&
                    wishlistProduct.sizeId === basketSizeId &&
                    wishlistProduct.colorId === basketColorId) {

                    basketProduct.find('.basketToWishlist').addClass('matchedWishlist');
                }
            });
        });
    }
}

$(window).on("wishToBasket_complete", function (data) {
    cartExistInWishlist();
})