$(function() {
    /*Placer le tableau point relais dans sa section respective*/
    // @A: Place it before h4
    $('.order_cgv .wrapperCgvTable').insertBefore("#bloc_5 h4:nth-of-type(2)");

    $('a.cms-page-module-link').click(function(){
        var hrefValue = $(this).attr("href");
        if (hrefValue.includes("https://site.booxi.eu/book_v2.html")) {
            DataLayerTrigger.rdvStore();
        }
    });

    window.addEventListener('wishToBasket_complete', function() {
        // Set flag to prevent popup
        isAddingToBasket = true;
        $('#wishlist_popup').remove();
    });
    
    window.addEventListener('wishToBasket_success', function(event) {
        // Set flag to prevent popup
        isAddingToBasket = true;
    });

    //POPUP article remove from wishlist
    window.addEventListener('removeFromWish_success', function () {
        popupBasketToWish();
        const wishToBasketPopupElement = document.querySelector('.wishtobasketpopup');
        if (wishToBasketPopupElement) {
            wishToBasketPopupElement.innerText = Translator.translate('product_removed_from_wishlist');
        }
    });

    if ($('#keep_alive_coupon').length) {
        const observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                if (!$('.bill_line.used_cp').length) {
                    $('#formPromo').show();
                }
            });
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }
});

let isAddingToBasket = false;

function popupBasketToWish() {
    if (isAddingToBasket) {
        // Reset isAddingToBasket to false after checking
        isAddingToBasket = false;
        // Skip the popup if adding to basket is in progress
        return;
    }

    if ($('#wishlist_popup').length) {
        $('#wishlist_popup').first().remove();
    }

    $('body').append(`
        <div id="wishlist_popup" class="lightbox">
            <span class="wishtobasketpopup">${Translator.translate('product_added_to_wishlist')}</span>
        </div>
    `);

    $('#wishlist_popup').fadeIn().delay(4000).fadeOut(function() {
        $(this).remove();
    });
}